<template>
  <div class="contentmenu" style="flex: 1;" :style="type==4||type==1?'width: 1107px;display:flex;background:#fff':''">
    <div style="display: flex;flex-direction: row;width: 100%" v-if="type==4||type==1">
      <div
          style="width: 190px;padding: 10px 0;box-sizing: border-box">

        <div @click="_clickXm(v)" class="ttt" :style="selected==v.id?'color:#1BBDA9':''"
             style="cursor: pointer;text-align: right;border-bottom: 1px dotted  #c6c6c6;" :key="i"
             v-for="(v,i) in list">
          <i class="ant-icon icon-caret-right" style="font-size: 12px;transform: scale(0.7)"></i>
          <div style="padding: 15px 10px 15px 0;font-size: 16px">{{ v.name }}</div>
        </div>


      </div>

      <div class="contentmenu-content" v-if="(type==4||type==1)&&list.length>0"
           style="padding: 20px;flex: 1;border-left: 1px solid #e0d5d5">
        <div style="display: flex;flex-direction: row;margin-bottom: 20px" v-if="type==4">
          <div style="margin-right: 30px;padding-bottom: 5px;color: #999999;cursor: pointer"
               @click="_clickSxm(1)"
               :style="xselected==1?'border-bottom: 2px solid #1BBDA9;color:#1BBDA9;font-weight:bold':''">项目介绍
          </div>
          <div style="margin-right: 30px;padding-bottom: 5px;color: #999999;cursor: pointer"
               @click="_clickSxm(2)"
               :style="xselected==2?'border-bottom: 2px solid #1BBDA9;color:#1BBDA9;font-weight:bold':''">科室与团队
          </div>
          <div style="margin-right: 30px;padding-bottom: 5px;color: #999999;cursor: pointer"
               @click="_clickSxm(3)"
               :style="xselected==3?'border-bottom: 2px solid #1BBDA9;color:#1BBDA9;font-weight:bold':''">优惠活动
          </div>
        </div>
        <div class="detail" v-if="xselected==1" v-html="detail.content"></div>
        <div v-if="xselected==2" style="display: flex;flex-direction: column">

          <div style="font-size: 20px;margin-bottom: 10px;color: #1BBDA9;font-weight: bold">科室</div>

          <div style="display: flex;flex-direction: row;flex-wrap: wrap">
            <div v-for="v in ks" :key="v.id" @click="seeDetail(v,5)" class="team-frame"
                 style="display: flex;flex-direction: row;cursor: pointer">
              <img :src="v.img.url" style="width:100px;height: 100px;object-fit: cover"/>
              <div style="display: flex;flex-direction: column;margin-left: 10px">
                <div style="font-size: 20px"><h4>{{ v.name }}</h4></div>
                <div style="font-size: 14px;line-height: 1.5;margin-top: 2px;color: #666;word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;">简介：{{ v.info }}
                </div>
              </div>
            </div>
          </div>

          <div style="font-size: 20px;margin: 20px 0 10px 0;color: #1BBDA9;font-weight: bold">团队</div>

          <div style="display: flex;flex-direction: row;width: 100%;flex: 1;flex-wrap: wrap">
            <div class="team-frame" style="cursor: pointer" v-for="v in team" :key="v.id" @click="seeDetail(v,4)">

              <img style="width: 100px;height: 150px;object-fit: cover" :src="v.img.url">
              <div style="display: flex;flex-direction: column;margin-left: 10px;flex: 1">
                <div style="font-size: 20px"><h4>{{ v.name }}</h4></div>
                <div style="font-size: 14px;color: #666;margin: 6px 0 0 0;">职称：{{ v.zw }}</div>
                <div style="font-size: 14px;color: #666;margin: 6px 0 0 0;word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;line-height: 1.5;">信息：{{ v.info }}
                </div>
              </div>

            </div>

          </div>

        </div>
        <div v-if="xselected==3" style="display: flex;flex-direction: row;flex-wrap: wrap">
          <div v-for="v in yhhd" :key="v.id" class="team-frame"
               @click="seeDetail(v,6)"
               style="display: flex;flex-direction: column;cursor: pointer">
            <img style="width: 100%;height: 275px;object-fit: cover" :src="v.img.url">
            <div style="padding: 10px;box-sizing: border-box">
              <div style="font-size: 14px;height: 43px;word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical">{{ v.name }}
              </div>
              <div style="display: flex;flex-direction: row;margin-top: 10px;align-items: flex-end">
                <div style="font-size: 20px;color: red">￥{{ v.price }}</div>
                <div style="font-size: 14px;color: #999999;margin-left: 20px"><s>￥{{ v.s_price }}</s></div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail" v-if="xselected==4" style="display: flex;flex-direction: row">
          <img style="width: 200px;height: 300px;object-fit: cover" :src="xdetail.img.url">
          <div style="margin-left: 20px">
            <div style="font-size: 23px;font-weight: bold">{{ xdetail.name }}</div>
            <div style="padding: 10px 0;color: #666">{{ xdetail.zw }}</div>
            <div style="color: #666">{{ xdetail.info }}</div>
          </div>
        </div>
        <div class="detail" v-if="xselected==5" v-html="xdetail.content"></div>
        <div class="detail" v-if="xselected==6" v-html="xdetail.content"></div>

      </div>
    </div>


    <!--    <div v-if="type!=4&&type!=1" class="contentmenu-menu">

                <div class="contentmenu-menu-unit" :style="selected==v.id?'color: #1BBDA9':'color:#414141'"
                     @click="handlemenuliststate(v)" v-for="(v,i) in menulist" :key="i">
                  <div class="contentmenu-menu-unit-left">
                    {{ v.title }}
                  </div>
                  <div class="contentmenu-menu-unit-right">
                    <i class="iconfont icon-you-"></i>
                  </div>
                </div>
        </div>-->


    <div v-if="type!=4&&type!=1" class="contentmenu-content">

      <div class="contentmenu-menu-content-news" style="flex: 1;display: flex;flex-direction: column">
        <div class="contentmenu-menu-content-news-top">
          <img class="contentmenu-menu-content-news-top-img" :src="banner" alt="">
        </div>
        <div class="title" style="font-size: 16px">
          {{ type == 2 ? '人才招聘' : type == 4 ? '项目介绍' : type == 5 ? '新闻资讯' : '' }}
        </div>
        <div class="title-solid"></div>
        <div class="contentmenu-menu-content-news-bottom">
          <div class="contentmenu-menu-content-news-bottom-unit"
               v-for="(v,i) in list" @click="handlerouter(v)" :key="i">
            <div style="font-size: 15px" class="contentmenu-menu-content-news-bottom-unit-left">
              {{ v.name }}
            </div>
            <div class="contentmenu-menu-content-news-bottom-unit-right">
              {{ v.create_time }}
            </div>
          </div>
        </div>


        <div style="text-align: center;margin-bottom: 10px">
          <el-pagination
              background
              @current-change="currentChange"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total">
          </el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
//平台公告横幅
import banner from '@/images/banner.png'
import {getAboutList, getKsSelect, getTdSelect, getYhhdSelect} from "@/api/about";

export default {
  data() {
    return {
      xselected: 1,
      selected: 1,
      id: '',
      detailShow: false,
      detail: '',
      banner: banner,
      list: [],
      type: '',
      total: '',
      page: 1,
      pageSize: 15,
      team: [],
      ks: [],
      yhhd: [],
      top_xselected: ''
      , xdetail: ''
      , xdetail_id: ''
    }
  },
  created() {


    let type = this.$route.query.type
    this.id = this.$route.query.id
    this.selected = this.$route.query.id
    let xselected = this.$route.query.xselected
    let xdetail_id = this.$route.query.xdetail_id

    if (xselected) {

      let ss = xselected;
      if (xselected == 5) {
        ss = 2
      }
      if (xselected == 4) {
        ss = 2

      }
      if (xselected == 6) {
        ss = 3

      }

      this._clickSxm(ss, false);
    }

    this.type = type;
    this.page = 1;

    setTimeout(() => {


      this._getList(type).then(() => {
        this.$nextTick(() => {


          if (xdetail_id != -1) {
            let key = -1;
            let xdetail = '';
            let ss = xselected;
            if (xselected == 5) {
              key = this.ks.findIndex(item => item.id == xdetail_id);
              xdetail = this.ks[key];
            }
            if (xselected == 4) {
              key = this.team.findIndex(item => item.id == xdetail_id);
              xdetail = this.team[key];
            }
            if (xselected == 3 || xselected == 6) {
              key = this.yhhd.findIndex(item => item.id == xdetail_id);
              xdetail = this.yhhd[key];
            }

            if (key != -1) {


              this.seeDetail(xdetail, ss, false)
            }
          }
        });


      });
    }, 500);


  },
  methods: {

    seeDetail(e, s, jump = true) {


      if (jump) {

        let query = {...this.$route.query, xselected: s, xdetail_id: e.id};
        this.$router.push({path: '', query})
      }

      this.top_xselected = this.xselected;
      this.xdetail_id = s.id;
      this.xdetail = e;
      this.xselected = s;
      console.log(this.xselected);


    },

    _clickSxm(e, jump = true) {

      this.xselected = e;


      if (jump) {

        let query = {...this.$route.query, id: this.id, xselected: this.xselected, xdetail_id: -1};
        this.$router.push({path: '', query})
      }


      if (e == 2) {

        getKsSelect({a_id: this.selected}).then(res => {


          this.ks = res;


        });
        getTdSelect({a_id: this.selected}).then(res => {


          this.team = res;


        });

      }
      if (e == 3) {


        getYhhdSelect({a_id: this.selected}).then(res => {

          this.yhhd = res;

        });


      }
    },

    _clickXm(e) {

      if (this.selected == e.id) {

        return
      }
      this.selected = e.id;
      this.xselected = 1;
      this.team = [];
      this.yhhd = [];


      let query = {...this.$route.query, id: e.id, xselected: 1, xdetail_id: -1};

      this.$router.push({path: '', query})

      this.detailShow = true;
      this.detail = e;

    },

    currentChange(e) {


      this.page = e;

      this._getList(this.type);


    },


    _getList(type) {

      if (type == 4) {

        this.pageSize = 9999999999;

      }

      return getAboutList({type: type, page: this.page, pageSize: this.pageSize}).then((res) => {


        this.list = res.data;

        if (type == 4 || type == 1) {

          if (this.id) {
            let k = this.list.findIndex(item => item.id == this.id);
            this.detail = res.data[k];
            this.selected = res.data[k].id;


          } else {
            this.detail = res.data[0];
            this.selected = res.data[0].id;

          }


        }
        this.total = res.total;


      });
    },


    handlerouter(e) {

      this.$router.push({path: 'detail?id=' + e.id})
      // this.detail = e;
      // this.detailShow = true;

      // this.$router.push(e)
    },
    handlemenuliststate(v) {
      if (this.selected == v.id) {
        return
      }

      this.selected = v.id
      this._getList();
    },


  }, watch: {
    "$route"(to) {
      //请求数据的方法

      let type = to.query.type
      if (this.type != type) {
        this.list = [];
        this.detail = '';
        this._getList(type);

      }

      let id = to.query.id

      if (id) {

        this.id = id;
        let k = this.list.findIndex(item => item.id == id);
        this.detail = this.list[k];
        this.selected = id;

      }
      this.type = type;
      this.page = 1;


    }
  },
}
</script>

<style scoped>
@import "~@/css/fonticon.css";
@import "~@/css/pagetitle.css";

.contentmenu {
  margin: 21px auto 27px auto;
  width: 917px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contentmenu-menu {
  /*width: 176px;*/
  background: white;
  margin-right: 8px;
}

.contentmenu-menu-unit {
  height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 2px #efefef solid;
  cursor: pointer;
  transition: 0.3s;
}

.contentmenu-menu-unit:first-of-type {
  border-top: 0;
}

.contentmenu-menu-unit-left {
  margin-right: 55px;
  font-size: 14px;
  font-weight: 400;
}

.icon-you- {
  font-size: 17px;
}

.contentmenu-content {
  background: white;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contentmenu-menu-content-news-top {
  width: 100%;
  object-fit: cover;
  margin: 0 auto 24px auto;
}

.contentmenu-menu-content-news-top-img {
  width: 100%;
  height: 100%;
  display: block;
}

.contentmenu-menu-content-news-bottom {
  padding: 20px 20px 68px 20px;
  flex: 1;
}

.contentmenu-menu-content-news-bottom-unit {
  padding-top: 15px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  align-items: center;
  cursor: pointer;
}

.contentmenu-menu-content-news-bottom-unit:first-of-type {
  padding-top: 0;
}

.contentmenu-menu-content-news-bottom-unit-right {
  color: #999999;
}

.ttt {
  color: #828282;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ttt:hover {

  color: #1BBDA9;
}

</style>
<style>


.active {
  background-color: #1BBDA9 !important;
}

.team-frame {
  display: flex;
  flex-direction: row;
  border: 1px solid #e8e8e8;
  width: 283px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 3px;
  box-sizing: border-box;


}

.team-frame:hover {

  border: 1px solid #1BBDA9;


}

.team-frame:nth-child(3n-2) {

  margin-left: 0;


}
</style>
<template>
  <div class="scrolllayout">
    <div class="scrolllayout-flex">
      <div class="about" style="height: 100%;display: flex;flex-direction: column;position: relative">
        <nav1></nav1>
          <contentmenu></contentmenu>
      </div>
    </div>
    <footer1></footer1>
  </div>
</template>

<script>
//导航栏组件
import nav1 from "../components/nav/nav1";
//页脚组件
import footer1 from "../components/footer/footer1";
//菜单组件
import contentmenu from "../components/contentmenu/contentmenu";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['setting'])
  },
  components: {
    //导航栏组件
    nav1,
    //页脚组件
    footer1,
    //内容菜单
    contentmenu,
  }
}
</script>

<style scoped>
.about {
  width: 100%;
}
</style>